@import '../../scss/variables';
@import '../../scss/mixins/index';
.share_modal {
	.top {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 24px;

		h4 {
			font-size: 24px;
			font-weight: 500;
			line-height: 30px;
			letter-spacing: -0.072px;
			@include color($fill-dm, $fill);
		}
	}

	.share_buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;

		.copy {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $primary;
			width: 31px;
			height: 31px;
			border-radius: 50%;
		}

		.share_button:hover:not(:active) {
			opacity: 0.75;
		}
	}
}
