@import '../../scss/variables';
@import '../../scss/mixins';
.performance_progress {
	// display: flex;
	display: grid;
	grid-template-columns: minmax(0%, 65px) auto minmax(0%, 45px);
	align-items: center;
	gap: 16px;
	padding: 16px 0;
	width: 100%;
	.currency {
		@include color($appBlack, $appWhite);
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0.07px;
	}

	.growth,
	.loss {
		font-size: 14px;
		font-weight: 500;
		line-height: normal;
	}
	.growth {
		color: $appGain;
	}
	.loss {
		color: $appFail;
	}
}
