@import "../../scss/variables";
@import "../../scss/mixins";

.instrument-select_component {
  position: relative;
  p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  input {
    width: 100%;
		border-radius: 4px;
		border: 1px solid #92929d;
		padding: 12px;
		height: 43px;
		font-size: 16px;
		font-weight: 500;
		line-height: 16.2px;
		color: #888888;
		@include background-color($fill, $fill-dm);

		&:disabled {
			cursor: not-allowed;
			// background: gray;
		}
		&::placeholder {
			color: $appGrey2;
			font-size: 14px;
			font-weight: 300;
			line-height: normal;
		}
		&.error {
			transition: border-color 0.3s ease-out;
			border-color: #d50000;
			color: #d50000;
		}
  }
  .instrument_modal {
    box-sizing: border-box;
    // display: inline-block;
    // white-space: nowrap;
    width: 400px;
    min-width: 300px;

    box-shadow: -2px 1px 12px 1px rgba(171, 171, 171, 0.69);
    -webkit-box-shadow: -2px 1px 12px 1px rgba(171, 171, 171, 0.69);
    -moz-box-shadow: -2px 1px 12px 1px rgba(171, 171, 171, 0.69);

    position: absolute;
    z-index: 999;
    padding: 1.5rem;
    background-color: $background-color-primary;
    display: none;
    @media screen and (max-width: 400px) {
      width: 350px;
      margin-left: 6px;
      .tags_container{
        justify-content: center;
      }
    }
    .tags_container {
      display: flex;
      box-sizing: border-box;
      .tag {
        background-color: #404140;
        margin-right: 1%;
        color: white;
        padding: 0.3rem 0.6rem;
        border-radius: 10px;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 14px;
        &:hover {
          background-color: rgb(211, 211, 211);
          color: black;
        }
      }
      #tag_active {
        background-color: rgb(211, 211, 211);
        color: black;
      }
    }
    .instruments_inner_container {
      margin-top: 1.2rem;
      height: 400px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 6px; /* Width of the scrollbar */
      }
      &::-webkit-scrollbar-thumb {
        background: #888; /* Color of the thumb */
        border-radius: 50px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color of the thumb on hover */
      }
      .instrument {
        border-bottom: 1px solid rgb(238, 238, 238);
        margin-top: -4px;
        padding: 0.3rem 0 0.8rem 0.3rem;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          background-color: rgb(181, 181, 181);
        }
      }
    }
  }
  #show_modal {
    display: block;
  }
  .error_message {
		color: #d50000;
		position: absolute;
		bottom: -20px;
		pointer-events: none;
	}
}
