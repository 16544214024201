.recomemdedation_card {
	padding-top: 8px;
	padding-bottom: 8px;
	display: flex;
	align-items: center;
	gap: 8px;

	.no_image {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #d9d9d9;
	}

	.name {
		font-size: 14px;
		font-weight: 400;
		line-height: 16.8px;
		letter-spacing: 0.07px;
	}

	.aside {
		margin-left: auto;

		button {
			font-size: 12px;
			padding-top: 4px;
			padding-bottom: 4px;
			width: 100%;
			width: 85px;
		}
	}
}
