@import '../../scss/variables';
@import '../../scss/mixins/index';

.tracker_wrapper {
	border-radius: 22px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	margin-bottom: 24px;
	background: #eee;
	.tracker_inner {
		padding: 14px 32px;
		border-radius: 20px;
		background: #8cfbde;
		position: relative;
		min-height: 181px;

		@media (max-width: 767px) {
			padding: 14px 12px;
		}

		.aside_image {
			position: absolute;
			top: 0;
			right: 0;

			@media (max-width: 1024px) {
				display: none;
			}
		}
		.title {
			color: #e63946;
			font-size: 12px;
			font-weight: 400;
			line-height: 18px;
		}
		.create_tracker {
			h3 {
				max-width: 440px;
				color: $fill-dm;
				font-size: 32px;
				font-weight: 500;
				line-height: 45px;
				letter-spacing: -0.16px;
			}
			button {
				padding: 8px 16px;
				color: $primary;
				font-size: 14px;
				font-weight: 400;
				line-height: 16.8px;
				letter-spacing: 0.07px;
				border-radius: 100px;
				border: 1px solid #e63946;
				background: #fff;
				cursor: pointer;
			}
		}
		.active_tracker {
			.current_day {
				margin-bottom: 4px;
				display: flex;
				align-items: center;
				h3 {
					font-size: 32px;
					font-weight: 500;
					line-height: 45px;
					letter-spacing: -0.16px;
					margin-right: 16px;
					color: $fill-dm;
				}
				.tracker_range {
					margin-right: 4px;
					color: $fill-dm;
					font-size: 14px;
					font-weight: 300;
					line-height: 16.8px;
					letter-spacing: 0.07px;
				}
				.days_count {
					border-radius: 9px;
					background: $appGain;
					padding: 2px 4px;
					color: #f7f7fc;
					font-size: 12px;
					font-weight: 300;
					line-height: 14.4px;
					letter-spacing: 0.06px;
				}
			}
			.tracker_title {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 4px;
				span {
					text-decoration: underline;
					color: #e63946;
					font-size: 12px;
					font-weight: 400;
					line-height: 18px;
				}
			}
			.tracker_days {
				margin-top: 5px;
				display: flex;
				align-items: center;
				gap: 32px;

				.single {
					display: flex;
					flex-direction: column;
					align-items: center;

					span:nth-child(2) {
						color: $appBlack;
						font-size: 14px;
						font-weight: 400;
						line-height: 18px;
					}
					span:nth-child(3) {
						color: #92929d;
						font-size: 8px;
					}
				}
			}
		}

		.tracker_loader {
			margin-top: 24px;
			display: flex;
			gap: 16px;
			flex-direction: column;
		}
	}

	.question {
		padding: 10px 20px;
		display: flex;
		align-items: center;
		gap: 20px;

		@media (max-width: 768px) {
			padding: 10px 8px;
			flex-wrap: wrap;

			span,
			small {
				width: 100%;
				display: block;
				color: $appBlack;
			}
		}

		span,
		small {
			color: $appBlack;
		}
		button {
			width: 100%;
			max-width: 85px;
			padding: 8px 16px;
			color: $primary;
			font-size: 14px;
			font-weight: 400;
			line-height: 16.8px;
			letter-spacing: 0.07px;
			border-radius: 100px;
			cursor: pointer;
			&:nth-child(2) {
				border: 1px solid #e63946;
				background: #fff;
			}
			&:nth-child(3) {
				border: none;
				background: $fill;
			}
		}
		.question_cancle {
			margin-left: auto;
			cursor: pointer;
		}
		@media screen and (max-width: 550px) {
			display: block;
			.question_text{
				margin-bottom: 1rem;
			}
			.question_cancle{
				margin-left: 7rem;
			}
			button{
				margin-right: 0.5rem;
			}
		}
	}
}

.no_trade_modal {
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;

		.header {
			display: flex;
			flex-direction: column;
			row-gap: 4px;

			h4 {
				font-size: 24px;
				font-weight: 500;
				line-height: 30px;
				letter-spacing: -0.072px;
				@include color($fill-dm, $fill);
			}
			span {
				@include color($fill-dm, $fill);
			}
		}
	}
	.options {
		display: flex;
		flex-direction: column;
		row-gap: 10px;

		.reason {
			@include color($fill-dm, $fill);
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
		}
	}
	.button {
		margin-top: 40px;
		width: 100%;
	}
}

.create_tracker_modal {
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;

		h4 {
			font-size: 24px;
			font-weight: 500;
			line-height: 30px;
			letter-spacing: -0.072px;
		}
	}
	.forms {
		display: flex;
		flex-direction: column;
		gap: 24px;

		.days {
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
			align-items: center;

			.day {
				padding: 8px 28px;
				border-radius: 4px;
				border: 1px solid #92929d;
				text-align: center;
				font-size: 16px;
				font-weight: 300;
				line-height: 22px;
				@include color($fill-dm, $fill);
				cursor: pointer;

				&.selected,
				&:hover {
					transition: background-color 0.3s ease;
					background: #8cfbde;
				}
			}
		}
	}
}
.success_modal {
	display: flex;
	flex-direction: column;
	text-align: center;

	span:nth-child(1) {
		@include color($appBlack, $appWhite);
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 28.8px;
		letter-spacing: 0.12px;
		margin-bottom: 32px;
	}
	span:nth-child(2) {
		@include color($appBlack, $appWhite);
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0.1px;
	}
}
