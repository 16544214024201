@import '../../../scss/variables';
@import '../../../scss/mixins';

.settings_flex {
	display: flex;
	.flex_1 {
		flex: 1;
		p {
			margin-bottom: 2rem;
			font-size: 18px;
			cursor: pointer;
			&:hover {
				color: #217eec;
			}
		}
		#active {
			color: #217eec;
		}
	}
	.flex_2 {
		flex: 6;
	}

	@media (max-width: 768px) {
		flex-direction: column;

		.flex_1 {
			flex: 1;
			display: flex;
			gap: 16px;
		}
		.flex_2 {
			flex: 2;
		}
	}
}
.settings_page {
	padding: 48px 32px;
	@include background-color($appWhite, $appBlack);
	border-radius: 8px;
	@media (max-width: 768px) {
		@include background-color(transparent, transparent);
		padding-right: 0;
		padding-left: 0;
	}
	.page_heading {
		@include color($appBlack, $appWhite);
		font-size: 24px;
		font-weight: 500;
		line-height: 19.2px;
		letter-spacing: 0.12px;
	}

	.profile_wrapper {
		.profile_picture_wrapper {
			margin-bottom: 31px;
			> span {
				@include color($fill-dm, $fill);
				font-size: 20px;
				font-weight: 500;
				line-height: 24px;
				letter-spacing: 0.1px;
			}

			.profile_picture {
				width: fit-content;
				position: relative;
				margin-top: 24px;
				img {
					width: 88px;
					height: 88px;
					border-radius: 50%;
					object-fit: cover;
					object-position: 50%;
				}
				.no_profile {
					width: 88px;
					height: 88px;
					border-radius: 50%;
					background-color: #d9d9d9;
				}

				label {
					cursor: pointer;
					position: absolute;
					right: -5px;
					bottom: 0;
				}
			}
		}
		.sec_header {
			width: 100%;
			max-width: 493px;
			display: flex;
			align-items: center;
			margin-bottom: 21px;
			justify-content: space-between;
			span {
				@include color($fill-dm, $fill);
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px;
				letter-spacing: 0.1px;
			}
			button {
				all: unset;
				cursor: pointer;
				color: $primary;
				font-size: 16px;
				font-weight: 500;
				line-height: 19.2px;
				letter-spacing: 0.08px;
				text-decoration-line: underline;
			}
		}
		.current_email {
			display: block;
			margin-bottom: 24px;
			@include color($fill-dm, $fill);
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0.1px;
			span {
				font-weight: 500;
			}
			@media (max-width: 767px) {
				font-size: 16px;
			}
		}
	}

	.trade_settings {
		display: flex;
		position: relative;
		.flex-item {
		}
		.flex-1 {
			flex: 3;
			margin-right: 30px;
			.heading {
				font-size: $heading-3;
				font-weight: 600;
			}
			.description {
				color: $appGrey;
				margin-top: 25px;
				line-height: 20px;
			}
			.confluences-inner_container {
				margin-top: 7%;
				.sub-heading {
					font-weight: 600;
					margin-bottom: 0.7rem;
				}
				.confleunce {
					display: flex;
					border-bottom: 1px solid $appGrey;
					padding: 2%;
					position: relative;
					margin-bottom: 0.3rem;
					p:first-child {
						color: $appGrey;
						font-size: $normal-text-size;
					}
					p:nth-child(2) {
						font-size: 16px;
						position: absolute;
						right: 0;
						margin-top: 5px;
						color: $primary;
					}
				}
			}
		}
		.flex-2 {
			flex: 2;
			button {
				width: 200px;
				position: absolute;
				right: 0;
			}
		}
		@media (max-width: 767px) {
			flex-direction: column;

			.flex-2 {
				margin-top: 24px;
				margin-right: auto;
				margin-left: auto;

				button {
					position: static;
				}
			}
		}
	}
}

.settings_modal {
	.top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;

		span {
			font-size: 20px;
			font-weight: 500;
			letter-spacing: 0.1px;
			@include color($appBlack, $appWhite);
		}

		svg path {
			@include dark {
				stroke: $appWhite;
			}
			@include light {
				stroke: $appBlack;
			}
		}
	}

	.forms {
		margin-top: 28px;
		margin-bottom: 28px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		.info {
			@include color($appBlack, $appWhite);
			font-size: 16px;
			font-weight: 400;
			line-height: 19.2px;
			letter-spacing: 0.08px;
		}
	}
	button {
		width: 100%;
	}

	.email_done {
		display: flex;
		flex-direction: column;
		gap: 32px;

		h4 {
			@include color($appBlack, $appWhite);
			text-align: center;
			font-size: 24px;
			font-weight: 500;
			line-height: 28.8px;
			letter-spacing: 0.12px;
		}

		span {
			@include color($fill-dm, $fill);
			text-align: center;
			font-size: 20px;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0.1px;
		}

		button {
			width: 100%;
		}
	}
}
