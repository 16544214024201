@import '../../scss/index.scss';
.auth-page {
	background-color: $background-color-primary;
	color: $main-text-color;
	min-height: 100vh;
	box-sizing: border-box;
	padding-top: 5rem;
	padding-bottom: 24px;
	.inner-container {
		background-color: $background-color-secondary;
		width: 30rem;
		margin: 0 auto;
		padding: 1rem 2.5rem;
		.heading {
			font-size: $heading-2;
			font-weight: 500;
			margin-top: 2rem;
			text-align: center;
		}
		.forget_password {
			display: block;
			text-align: center;
			margin: 12px auto;
		}
		.o-auth_btns_container {
			margin: 2rem 0 1.5rem 0;
		}
		.or-flex {
			display: flex;
			align-items: center;
			width: 80%;
			margin: 0 auto;
			margin-bottom: 1rem;
			.flex-line {
				flex: 2;
				border: none;
				border-top: 1px solid $input-border-color;
			}
			.flex-text {
				flex: 1;
				text-align: center;
				font-weight: 300;
			}
		}
		.forgot_password {
			cursor: pointer;
			margin-top: 12px;
			text-align: right;
		}
		.submit_btn {
			width: 100% !important;
			margin-top: 1rem;
		}
		.forms_input {
			display: flex;
			flex-direction: column;
			row-gap: 24px;
		}
		.alternative-option {
			font-weight: 400;
			font-size: 14px;
			text-align: center;
			margin: 1rem 0;
			.alternate-text{
				color: #8CFBDE;
			}
		}
		.signup-caution {
			font-weight: 300;
			font-size: 14px;
			text-align: center;
			width: 90%;
			margin: 1rem auto;
		}
	}
	@media screen and (max-width: 550px) {
		.inner-container {
			width: 90%;
		}
	}
}
