@import '../../../scss/mixins';
@import '../../../scss/variables';
.journal_page {
	.top {
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.page_heading {
			@include color($appBlack, $appWhite);
			font-size: 24px;
			font-weight: 500;
			line-height: 28.8px;
		}
		.buttons_wrapper {
			display: flex;
			align-items: center;
			gap: 20px;

			@media (max-width: 768px) {
				display: none;
			}

			button {
				width: 189px;
			}
		}
		.dropdown_wrapper {
			display: none;
			@media (max-width: 768px) {
				display: block;
			}
		}
	}
	.journal_table_wrapper {
		padding: 20px;
		margin-top: 24px;
		border-radius: 8px;
		background: #fff;
		@include background-color($appWhite, $appBlack);

		@media (max-width: 768px) {
			padding: 0;
		}
		.filters {
			padding: 9px 14px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 10px;
			@include background-color($fill, $fill-dm);

			.ant-picker {
				background-color: transparent;
			}

			.filter {
				border-radius: 8px;
				background-color: #1d3557;
				padding: 8px 12px;
				display: flex;
				align-items: center;
				gap: 8px;

				span {
					color: #fff;
					font-size: 14px;
					font-weight: 400;
					line-height: 16.8px;
					letter-spacing: 0.07px;
				}
			}

			.ant-select-selector {
				background-color: transparent;
			}
		}
	}
}

.export_modal {
	.top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;

		span {
			font-size: 20px;
			font-weight: 500;
			letter-spacing: 0.1px;
			@include color($appBlack, $appWhite);
		}

		svg path {
			@include dark {
				stroke: $appWhite;
			}
			@include light {
				stroke: $appBlack;
			}
		}
	}

	.format {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
		gap: 10px;
		> span {
			@include color($fill-dm, $fill);
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0.08px;
			margin-bottom: 16px;
		}
	}

	.export_range {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
		> span {
			@include color($fill-dm, $fill);
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0.08px;
			margin-bottom: 16px;
		}

		.date_select {
			display: flex;
			align-items: center;

			.ant-picker {
				width: 100%;
			}
		}
	}
	.button {
		width: 100%;
	}
}
