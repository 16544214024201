.lightmode_theme {
	--landing-page-color: #fff;
	--color-primary: #f7f7fc;
	--background-color-primary: #fff;
	--background-color-secondary: #f7f7fc;
	--landing-nav-background: linear-gradient(270deg, rgba(250, 250, 250, 0.8) -12.81%, rgba(250, 250, 250, 0) 108.33%);
	--main-text-color: #000;
	--faded-text-color: #01161e;

	--landing-btn-fill: rgba(4, 246, 181, 0.2);
	--landing-btn-border: 1px solid #67f9d2;
	--landing-btn-text-color: #31be98;
	--reason-container-border: 1px solid rgba(146, 146, 157, 0.7);
	--reason-container-fill: linear-gradient(257deg, #fafafa -12.88%, rgba(250, 250, 250, 0.3) 109.21%);
	--input-border-color: #92929d;
	--input-background-color: #f7f7fc;
	--sk-loading-from-color: rgba(0, 0, 0, 0.06);
	--sk-loading-to-color: rgba(0, 0, 0, 0.15);
	--gradient-container-background: linear-gradient(257deg, #FAFAFA -12.88%, rgba(250, 250, 250, 0.30) 109.21%);;
}
