@import '../../scss/variables';
@import '../../scss/mixins/index';
.dots {
	display: flex;
	align-items: center;
	gap: 4px;

	span {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		@include background-color($appBlack, $appWhite);
	}
}
