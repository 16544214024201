@import "../../../scss/index.scss";

.landing_nav {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $main-text-color;
  .main_wrapper {
    .flex_container {
      display: flex;
      width: 100%;
      align-items: center;
    }
    background: $landing-nav-background;
    width: 90%;
    box-sizing: border-box;
    height: 5rem;
    border-radius: 100px;
    border: 1px solid rgba(136, 136, 147, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 3%;
    padding-right: 3%;
    .navlinks {
      text-decoration: none;
      &:hover {
        color: $mid-green !important;
      }
    }
    #login-text {
      color: $main-text-color;
    }
    .flex_1 {
      .app_logo {
        span {
          font-family: "Gemunu Libre", sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 28.8px;
          letter-spacing: 0.12px;
          padding-left: 0.6rem;
          color: $main-text-color;
        }
      }
    }
    .flex_2 {
      margin-left: auto;
      .menu_icon {
        display: none;
      }
      ul {
        display: flex;
        align-items: center;
        li {
          margin-right: 20px;
          list-style-type: none;
          font-size: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 550px) {
    #main_wrapper_expandable {
      border-radius: 32px;
      align-items: unset;
      justify-content: unset;
      padding-top: 1rem;
      height: 17.5rem;
    }
    .main_wrapper {
      padding-left: 3%;
      padding-right: 0;
      height: 4rem;
      .flex_container {
        justify-content: unset;
      }
      .flex_1 {
        .app_logo {
          padding-left: 1rem;
          img {
            width: 15px;
          }
          span {
            font-size: 20px;
          }
        }
      }
      .flex_2 {
        .menu_icon {
          display: block;
        }
        #login-text {
          display: none;
        }
        #landing_btn {
          font-size: 14px;
          height: 8px;
          width: 90px;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      .mid_nav_items {
        display: none;
      }

      .mobile_nav_options {

        hr {
          margin-top: 0.7rem;
          margin-bottom: 1rem;
          border: none;
          background: linear-gradient(
            90deg,
            rgba(146, 146, 157, 0) 2.86%,
            #92929d 45.21%,
            rgba(146, 146, 157, 0) 100%
          );
          height: 1px;
        }
        ul {
          padding-left: 1rem;
          padding-right: 1rem;
          li {
            list-style-type: none;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            margin-bottom: 4%;
          }
          #landing_btn {
            font-size: 14px;
            height: 8px;
            width: 100%;
            flex-direction: column;
            justify-content: center;
          }
          .mobile_menu_signup_btn {
            margin-top: 0.7rem;
          }
          .mobile_menu_login_btn {
            border: none !important;
            color: $main-text-color !important;
          }
        }
      }
    }
  }
}

.why-us_section {
  .heading {
    font-size: $heading-1;
    font-weight: 500;
    margin-bottom: 2rem;
  }
  .sub-text {
    margin-bottom: 5rem;
  }
  .reason-container {
    border: $reason-container-border;
    background: $reason-container-fill;
    border-radius: 12px;
    display: flex;
    padding: 4rem 2rem;
    margin-bottom: 4rem;
    .flex-1 {
      flex: 1;
    }
    .flex-2 {
      flex: 1;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding-left: 4rem;
      padding-right: 4rem;
      .title {
        font-size: $heading-2;
        font-weight: 500;
        margin-bottom: 2rem;
      }
      .description {
        font-size: $normal-text-size;
        margin-bottom: 2rem;
      }
    }
  }
  .why-container {
    padding-bottom: 5rem;
    margin-top: 10rem;
    .heading-flex {
      display: flex;
      .flex-2 {
        margin-left: auto;
      }
    }
    .why-flex {
      display: flex;
      gap: 50px;
      // background-color: green;
      height: 100%;
      .flex-1 {
        flex: 1;
        height: 100%;
      }
      .flex-2 {
        flex: 1;
        border: $reason-container-border;
        border-left: none;
        padding: 3rem;
        background: $reason-container-fill;
        border-radius: 0px 12px 12px 0px;
        .why-heading {
          font-size: $heading-3;
          font-weight: 500;
          margin-bottom: 1rem;
        }
        .why-description {
          font-weight: 300;
          color: $faded-text-color;
        }
        hr {
          margin-top: 2rem;
          margin-bottom: 2rem;
          border: none;
          background: linear-gradient(
            90deg,
            rgba(146, 146, 157, 0) 2.86%,
            #92929d 45.21%,
            rgba(146, 146, 157, 0) 100%
          );
          height: 1px;
        }
      }
    }
  }
}

.testimonial-card_container {
  border: 1px solid #92929d;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 1.5rem;
  width: 350px;
  .testimony {
    color: $faded-text-color;
    font-weight: 300;
    span {
      font-size: $heading-1;
    }
  }
  .person-detail {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 12px;
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
    .name {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 790px) {
  .why-us_section {
    .heading {
      font-size: $heading-1-mobile;
    }
    .reason-container {
      display: block;
      padding: 1rem;
      .flex-2 {
        padding-left: 0;
        padding-right: 0;
        .title {
          font-size: $heading-2-mobile;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
    .why-container {
      .heading-flex {
        display: block;
        margin-bottom: 5rem;
        .flex-2 {
          margin-left: 0;
          margin-top: -15%;
        }
      }

      .why-flex {
        display: block;
        .flex-1 {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
