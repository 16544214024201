@import "../../../scss/mixins";
@import "../../../scss/variables";
.analytics_page {
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  .top_cards {
    margin-top: 32px;
    display: flex;
    gap: 20px;
    margin-bottom: 24px;
    flex-wrap: wrap;

    .info_rate_card {
      flex: 1 1 0%;
      @media (max-width: 1024px) {
        flex: 1 1 200px;
      }
      @media (max-width: 768px) {
        flex: 1 1 45%;
      }
    }
  }

  .chart_performance_wrapper {
    display: flex;
    gap: 32px;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
    .chart_wrapper {
      flex: 2.5 1 0%;
      padding: 20px;
      width: 100%;
      height: 400px;
      border-radius: 8px;
      @include background-color($appWhite, $appBlack);

      .chart_top {
        margin-bottom: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .title {
          @include color($appBlack, $appWhite);
          font-size: 16px;
          font-weight: 500;
          line-height: 19.2px;
          letter-spacing: 0.08px;
        }
      }
    }
    .performance_wrapper {
      border-radius: 8px;
      padding: 20px 12px;
      width: 100%;
      max-width: 378px;
      @include background-color($appWhite, $appBlack);
      height: 100%;
      max-height: 392px;
      overflow-y: auto;

      @media (max-width: 1024px) {
        max-height: 100%;
        max-width: 100%;
      }

      .top {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span {
          @include color($appBlack, $appWhite);
          font-size: 16px;
          font-weight: 500;
          line-height: 19.2px;
          letter-spacing: 0.08px;
        }
      }
    }
  }
  .pair_summary_wrapper {
    padding: 20px 18px;
    border-radius: 8px;
    @include background-color($appWhite, $appBlack);
    margin-top: 32px;
    .top {
      margin-bottom: 24px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 18px;
      > span {
        @include color($appBlack, $appWhite);
        font-size: 16px;
        font-weight: 500;
        line-height: 19.2px;
        letter-spacing: 0.08px;
        margin-right: auto;
      }
    }
    .summary_card_wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 16px 24px;

      .summary_card {
        flex: 1 1 157px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 8px;
        @include background-color($fill, $fill-dm);
        @include light {
          border: 1px solid $appGrey;
        }
        @include dark {
          border: 1px solid $appGrey2;
        }

        > span {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          letter-spacing: 0.07px;
          @include color($fill-dm, $fill);
        }
        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .total_trade {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 19.2px;
            letter-spacing: 0.08px;
            @include color($appBlack, $appWhite);
          }

          > .percentage {
            font-family: "Mulish", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 14.4px;
            letter-spacing: 0.06px;

            &.growth {
              color: #217eec;
            }
            &.loss {
              color: #f85454;
            }
          }
        }
      }
    }
  }
}
.analytics_page::-webkit-scrollbar {
	display: none; /* Chrome, Safari, Opera */
  }
