.darkmode_theme {
	--landing-page-color: #121212;
	--color-primary: #01161e;
	--background-color-primary: #01161e;
	--background-color-secondary: #252836;
	--landing-nav-background: linear-gradient(270deg, rgba(47, 47, 47, 0.8) -12.81%, rgba(47, 47, 47, 0) 108.33%);
	--main-text-color: #fff;
	--faded-text-color: #f7f7fc;

	--landing-btn-fill: transparent;
	--landing-btn-border: 1px solid #8cfbde;
	--landing-btn-text-color: #8cfbde;
	--reason-container-border: 1px solid rgba(146, 146, 157, 0.5);
	--reason-container-fill: linear-gradient(257deg, rgba(47, 47, 47, 0.8) -12.88%, rgba(47, 47, 47, 0) 109.21%);
	--input-border-color: #92929d;
	--input-background-color: #252836;
	--sk-loading-from-color: rgba(217, 217, 217, 0.06);
	--sk-loading-to-color: rgba(217, 217, 217, 0.15);
	--gradient-container-background: linear-gradient(
		257deg,
		rgba(47, 47, 47, 0.8) -12.88%,
		rgba(47, 47, 47, 0) 116.7%
	  );
}
