@import '../../scss/variables';
@import '../../scss/mixins';
.dashboard_layout {
	@include background-color($fill, $fill-dm);
	min-height: 100vh; 
	.dashboard_header {
		position: fixed;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		align-items: center;
		min-height: 78px;
		padding-left: 24px;
		padding-right: 24px;
		border-bottom: 1px solid #c8c8c9;
		@include background-color($appWhite, $appBlack);

		@include dark {
			border-bottom: 1px solid #3c4156;
		}

		.dash_icon {
			display: flex;
			align-items: center;
			cursor: pointer;

			svg {
				path {
					@include dark {
						stroke: white;
					}
				}
			}

			@media (min-width: 3693px) {
				display: none;
			}
		}

		.app_logo {
			margin-left: 32px;
			margin-right: 89px;
			display: flex;
			align-items: center;
			column-gap: 4px;

			@media (max-width: 768px) {
				margin-right: 0;
			}

			span {
				font-family: 'Gemunu Libre', sans-serif;
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: 28.8px;
				letter-spacing: 0.12px;
				@include color($appBlack, $appWhite);
			}
		}

		.greeting {
			font-size: 20px;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0.1px;
			@include color($appBlack, $appWhite);

			span {
				@include color($appGrey, $appGrey2);
			}

			@media (max-width: 768px) {
				display: none;
			}
		}

		.aside {
			margin-left: auto;
			display: flex;
			align-items: center;
			gap: 20px;

			@media (max-width: 768px) {
				display: none;
			}
		}

		.user {
			display: flex;
			align-items: center;
			gap: 7px;
			.user_image,
			.no_profile {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: #d9d9d9;
				object-fit: cover;
			}
			.details {
				display: flex;
				flex-direction: column;
				gap: 4px;

				span:nth-child(1) {
					color: $appBlack;
					font-size: 14px;
					font-weight: 400;
					line-height: 16.8px;
					letter-spacing: 0.07px;

					@include color($appBlack, $appWhite);
				}

				span:nth-child(2) {
					color: #92929d;
					font-size: 12px;
					font-weight: 400;
					line-height: 14.4px;
					letter-spacing: 0.06px;
				}
			}
		}

		.dropdown_mobile {
			margin-left: auto;

			@media (min-width: 769px) {
				display: none;
			}

			.drop {
				display: flex;
				align-items: center;
				gap: 12px;
			}
		}

		.sk_greetings {
			@media (max-width: 768px) {
				display: none;
			}
		}

		.m_sk_user {
			margin-left: auto;
			@media (min-width: 769px) {
				display: none;
			}
		}
	}

	.dasboard_sidebar {
		z-index: 2;
		// padding-right: 16px;
		padding-top: 74px;
		padding-bottom: 24px;
		position: fixed;
		left: 0;
		bottom: 0;
		height: calc(100vh - 78px);
		overflow-x: hidden;
		overflow: auto;
		border-right: 1px solid #c8c8c9;
		max-width: 274px;
		width: 98px;
		transition: all 0.3s ease;
		@include background-color($appWhite, $appBlack);

		@include dark {
			border-right: 1px solid #3c4156;
		}

		.nav_list {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding-right: 16px;
		}

		&.open {
			transition: width 0.3s ease;
			width: 274px;

			a {
				span {
					display: block;
				}
			}
			.sk_loading .sk_nav {
				display: block;
			}
		}

		@media (max-width: 768px) {
			left: -274px;
			width: 274px !important;

			a {
				span {
					display: block !important;
				}
			}

			&.open {
				transition: all 0.3s ease;
				left: 0;
			}
		}

		a {
			all: unset;
			display: flex;
			align-items: center;
			column-gap: 8px;
			padding: 16px 24px;
			cursor: pointer;
			transition: all 0.3s ease;

			&:hover {
				@include background-color($fill, $fill-dm);
			}

			span {
				display: none;
				font-size: 16px;
				font-weight: 400;
				line-height: 19.2px;
				letter-spacing: 0.08px;
				@include color($appBlack, $appWhite);
			}
			span,
			svg {
				transition: color 0.3s ease;
			}
			svg path {
				stroke: $appBlack;

				@include dark {
					stroke: $appWhite;
				}
			}

			&.active {
				@include background-color($fill, $fill-dm);

				span {
					color: #e63946;
					font-weight: 500;
				}
				svg path {
					stroke: #e63946;
				}
			}
		}

		.sk_loading {
			display: flex;
			align-items: center;
			column-gap: 8px;
			padding: 16px 24px;

			.sk_icon {
				min-width: 30px;
				width: 30px;
				min-height: 30px;
				height: 30px;
				border-radius: 100%;
			}
			.sk_nav {
				display: none;
			}
		}

		@media (min-width: 3693px) {
			width: 100%;

			a {
				span {
					display: block;
				}
			}
		}

		.go_pro {
			max-width: 226px;
			margin: 0 auto;
			margin-top: 32px;
			padding: 9px 8px;
			width: 100%;
			border-radius: 1000px;
			border: 1px solid rgba(140, 251, 222, 0.4);
			background: #8cfbde;
			display: flex;
			align-items: center;
			gap: 12px;

			> span {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px;
				color: $appBlack;
			}
		}
		.pro_btn {
			cursor: pointer;
			padding: 4px 16px;
			border-radius: 100px;
			border: 1px solid $primary;
			background: #fff;
			display: flex;
			align-items: center;
			gap: 2px;

			span {
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 14.4px;
				letter-spacing: 0.06px;
				color: $primary;
			}
		}
	}

	.dashboard_content {
		// height: 200% !important;
		padding-top: 120px;
		padding-bottom: 20px;
		padding-left: 40px;
		padding-right: 40px;
		max-width: 3096px;
		margin-left: 98px;
		transition: margin-left 0.3s ease;

		&.open {
			transition: margin-left 0.3s ease;
			margin-left: 274px;
		}

		@media (max-width: 768px) {
			margin-left: 0;
			&.open {
				margin-left: 0;
			}
		}

		@media (max-width: 500px) {
			padding-left: 15px;
			padding-right: 15px;
		}
		@media (min-width: 3693px) {
			margin: 0 auto;

			&.open {
				margin: 0 auto;
			}
		}
	}
}

.ant-switch.theme {
	height: 32px;
	width: 64px;
	display: flex;
	align-items: center;
	background-color: #252836;
	&.ant-switch-checked {
		background-color: #f7f7fc;
	}

	.ant-switch-handle {
		width: 28px;
		height: 28px;

		&::before {
			border-radius: 100%;
		}
	}

	.ant-switch-inner {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 28px;
		border-radius: 100%;
	}

	.ant-switch-inner-checked,
	.ant-switch-inner-unchecked {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.ant-switch-inner-unchecked {
		margin-top: 0;
	}
}

.m_logout {
	display: flex;
	align-items: center;
	gap: 8px;
}

.floating-journal-btn {
	position: fixed;
	z-index: 99;
	top: 90%;
	right: 10px;

	box-shadow: -3px 1px 14px -3px rgba(87, 9, 9, 0.89);
	-webkit-box-shadow: -3px 1px 14px -3px rgba(87, 9, 9, 0.89);
	-moz-box-shadow: -3px 1px 14px -3px rgba(87, 9, 9, 0.89);
	visibility: hidden;

	@media screen and (max-width: 550px) {
		visibility: visible;
	}
}
