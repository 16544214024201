@import '../../scss/variables';
@import '../../scss/mixins/index';
.request_payout {
	.payout_account_details,
	.review_payout {
		width: 100%;
		.heading {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 16px;

			h4 {
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 19.2px;
				letter-spacing: 0.08px;
			}
		}

		table {
			width: 100%;
			border-collapse: collapse;
			tr td {
				border-bottom: 1px solid $appGrey2;
				padding: 12px;
			}
		}
	}

	.fee_wrapper {
		position: relative;
		.fee {
			display: flex;
			align-items: center;
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	.info {
		@include color($fill-dm, fill);
		text-align: center;
		font-size: 16px;
		font-weight: 400;
		line-height: 19.2px;
		letter-spacing: 0.08px;
		margin-top: 28px;
		display: block;
	}
}
