@import '../../../scss/variables';
@import '../../../scss/mixins/index';
.tracker_details_wrapper {
	padding-left: 40px;
	display: flex;
	align-items: flex-start;
	gap: 42px;

	@media (max-width: 1024px) {
		padding-right: 40px;
	}
	@media (max-width: 600px) {
		padding-right: 15px;
		padding-left: 15px;
	}

	.summary {
		padding-top: 120px;
		flex: 2 1 0%;

		@media (max-width: 1024px) {
			width: 100%;
		}

		.title_wrapper {
			display: flex;
			align-items: center;
			gap: 20px;
			flex-wrap: wrap;

			.title {
				@include color($appBlack, $appWhite);
				font-size: 20px;
				font-weight: 500;
				line-height: 26px;

				@media (max-width: 768px) {
					width: 100%;
				}
			}

			.delete {
				margin-left: auto;
				width: 48px;
				height: 48px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: rgba(248, 84, 84, 0.2);
				cursor: pointer;
			}
			button {
				max-width: 189px;
				width: 100%;

				@media (max-width: 768px) {
					margin-right: auto;
				}
			}
		}

		.top_cards {
			margin-top: 32px;
			margin-bottom: 24px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			border-radius: 8px;
			@include background-color($appWhite, $appBlack);
			padding: 20px 16px;
			gap: 8px;

			.card {
				padding: 8px;
				flex: 1 1 0%;
				display: flex;
				flex-direction: column;
				gap: 4px;
				border-right: 1px solid #eee;

				@media (max-width: 600px) {
					flex: 1 1 46%;
				}

				&:last-child {
					border-right: none;
				}
			}
		}
		.chart {
			padding: 20px;
			margin-bottom: 24px;
			border-radius: 8px;
			@include background-color($appWhite, $appBlack);
			display: none;
		}

		.table_wrapper,
		.recomendation {
			width: 100%;
			// display: none;
			margin-bottom: 24px;
			padding: 20px 8px;
			border-radius: 6px;
			@include background-color($appWhite, $appBlack);

			.flex_header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 16px;
				flex-wrap: wrap;

				@media (max-width: 600px) {
					flex-direction: column;
				}
			}
		}
		.recomendation {
			display: block;
			// display: none;
			margin-bottom: 0;
		}

		.empty_state {
			display: flex;
			min-height: 200px;
			align-items: center;
			justify-content: center;
			span {
				max-width: 533px;
				text-align: center;
				color: #92929d;
				font-size: 18px;
				font-weight: 400;
				line-height: 26px;
				letter-spacing: -0.018px;
			}
		}
    .recomendation{
      margin-bottom: 2rem;
      .ai-generate-btn{
        margin: 0 autos;
      }
      .recommendation-wrapper{
        padding: 5%;
        font-size: 1rem;
        line-height: 150%;
      }
    }
	}
	aside,
	.m_details {
		flex: 1 1 0%;
		padding: 24px 20px;
		padding-top: 100px;
		border-radius: 6px;
		// min-width: 200px;
		@include background-color($appWhite, $appBlack);

		.tracker_status {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 4px;
			color: #217eec;

			div {
				font-weight: 400;
				background-color: #217eec;
				border-radius: 100%;
				height: 6px;
				width: 6px;
			}
		}

		.top_card {
			padding: 12px 16px;
			border-radius: 14px;
			background: #8cfbde;
			margin-top: 16px;

			.details_wrapper {
				margin-bottom: 32px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.title {
					color: #e63946;
					font-size: 12px;
					font-weight: 400;
					line-height: 18px;
				}
				.day {
					color: $fill-dm;
					font-size: 24px;
					font-weight: 500;
					line-height: 30px;
					letter-spacing: -0.072px;
				}
				.details {
					display: flex;
					flex-direction: column;
				}
				.date {
					color: $fill-dm;
				}

				.goalIcon {
				}
			}
			.question {
				display: flex;
				flex-direction: column;
				gap: 7px;

				.button_wrpper {
					display: flex;
					flex-wrap: wrap;
					gap: 12px;
					align-items: center;
				}
				button {
					width: 100%;
					max-width: 85px;
					padding: 4px 16px;
					color: $primary;
					font-size: 12px;
					font-weight: 400;
					line-height: 16.8px;
					letter-spacing: 0.07px;
					border-radius: 100px;
					cursor: pointer;
					&.yes {
						border: 1px solid #e63946;
						background: #fff;
					}
					&.no {
						border: none;
						background: $fill;
					}
				}
			}
		}

		.tracker_days {
			border-radius: 20px;
			@include background-color($fill, $fill-dm);
			margin-top: 20px;
			display: flex;
			align-items: center;
			padding: 10px;
			justify-content: space-around;

			.single {
				display: flex;
				flex-direction: column;
				align-items: center;

				span:nth-child(2) {
					@include color($appBlack, $appWhite);
					font-size: 14px;
					font-weight: 400;
					line-height: 18px;
				}
				span:nth-child(3) {
					color: #92929d;
					font-size: 8px;
				}
			}
		}

		.tracker_days_listing {
			margin-top: 20px;
			.title {
				display: block;
				margin-bottom: 4px;
				@include color($appBlack, $appWhite);
			}
			.sub_text {
				font-family: Rubik;
				font-size: 12px;
				font-style: normal;
				font-weight: 300;
				line-height: 14.4px;
				letter-spacing: 0.06px;
			}
			.days_count {
				border-radius: 9px;
				background: $appGain;
				padding: 2px 4px;
				color: #f7f7fc;
				font-size: 12px;
				font-weight: 300;
				line-height: 14.4px;
				letter-spacing: 0.06px;
			}

			.days_listing {
				margin-top: 12px;
				display: flex;
				justify-content: space-around;
				gap: 26px;
				flex-wrap: wrap;

				.day {
					min-height: 48px;
					padding: 8px 7px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 4px;
					border-radius: 16px;
					border-radius: 16px;
					border: 1px solid $fill-dm;
					min-width: 34px;

					&.done {
						border: 1px solid #57f18c;
						background: #57f18c;
					}

					&.missed {
						border: 1px solid #d7d7d7;
						background: rgba(215, 215, 215, 0.5);
					}

					span {
						&:nth-child(1) {
							// color: $appGrey;
							color: $fill-dm;
							text-align: center;
							font-size: 10px;
							font-weight: 300;
							line-height: 14px;
							letter-spacing: 0.05px;
						}
						&:nth-child(2) {
							color: $fill-dm;
							text-align: center;
							font-size: 12px;
							font-weight: 400;
							line-height: 14px;
							letter-spacing: 0.06px;
						}
					}
				}
			}
			.keys {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 12px;
				margin-top: 16px;
				> span {
					color: $appBlack;
					font-size: 12px;
					font-weight: 400;
					line-height: 14.4px;
					letter-spacing: 0.06px;
				}

				.done,
				.missed {
					display: flex;
					align-items: center;
					gap: 2px;

					span:nth-child(2) {
						color: $appBlack;
						font-size: 12px;
						font-weight: 300;
						line-height: 14.4px;
						letter-spacing: 0.06px;
					}
				}
			}
		}
		.missed_days {
			margin-top: 23px;
			.title {
				display: block;
				margin-bottom: 4px;
				@include color($appBlack, $appWhite);
			}
			.sub_text {
				font-family: Rubik;
				font-size: 12px;
				font-style: normal;
				font-weight: 300;
				line-height: 14.4px;
				letter-spacing: 0.06px;
			}
			.missed_day_card_wrapper {
				display: flex;
				flex-direction: column;
				row-gap: 12px;
				margin-top: 12px;
			}
			.missed_day_card {
				cursor: pointer;
				display: flex;
				gap: 4px;
				flex-direction: column;
				padding: 8px;
				border-radius: 8px;
				border: 0.5px solid $fill-dm;
				span:nth-child(1) {
					@include color($appBlack, $appWhite);
				}
				span:nth-child(2) {
					@include color($fill-dm, $fill);
					font-size: 12px;
					font-style: normal;
					font-weight: 300;
					line-height: 14px;
					letter-spacing: 0.06px;
				}
			}
		}
	}

	aside {
		@media (max-width: 1024px) {
			display: none;
		}
	}
	.m_details {
		margin-bottom: 24px;
		padding-top: 24px;
		@media (min-width: 1024px) {
			display: none;
		}
	}
	&.share {
		margin: 0 auto;
		max-width: 1200px;

		.summary {
			padding-top: 0;
		}
		aside {
			padding-top: 10px;
		}
	}
}
