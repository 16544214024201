@mixin light() {
	@media (prefers-color-scheme: light) {
		@at-root #{selector-append(&, ':where(:root:not([data-theme=light]):not([data-theme=dark]) *)')} {
			@content;
		}
	}
	@at-root #{selector-append(&, ':where(:root[data-theme=light] *)')} {
		@content;
	}
}

@mixin dark() {
	@media (prefers-color-scheme: dark) {
		@at-root #{selector-append(&, ':where(:root:not([data-theme=light]):not([data-theme=dark]) *)')} {
			@content;
		}
	}

	@at-root #{selector-append(&, ':where(:root[data-theme=dark] *)')} {
		@content;
	}
}

@mixin background-color($light: inherit, $dark: inherit) {
	@include light {
		background-color: $light;
	}
	@include dark {
		background-color: $dark;
	}
}

@mixin color($light: inherit, $dark: inherit) {
	@include light {
		color: $light;
	}
	@include dark {
		color: $dark;
	}
}
