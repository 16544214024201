@import '../../scss/index.scss';
.seleton_loader_item {
	width: 100%;
	height: 16px;
	border-radius: 4px;
	background: linear-gradient(90deg, var(--sk-loading-from-color) 25%, var(--sk-loading-to-color) 37%, var(--sk-loading-from-color) 63%);
	background-size: 400% 100%;
	animation-name: placeholderAnimate;
	animation-duration: 1.4s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
	@keyframes placeholderAnimate {
		0% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0 50%;
		}
	}
}
