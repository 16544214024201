@import '../../scss/variables';
.o-auth_btn {
	background-color: transparent !important;
	border: 1px solid $input-border-color !important;
	color: $main-text-color;
	font-size: 14px;
	font-weight: 300;
	border-radius: 4px;
	width: 100% !important;
	padding: 10px 12px;
}
