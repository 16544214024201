@import "../../scss/index.scss";

.footer_wrapper {
  footer {
    border-radius: 80px 80px 0px 0px;
    background: #debac0;
    height: 30rem;
    padding: 48px 71px 75px 71px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    p {
      font-size: 6.2rem;
      text-align: center;
      font-weight: 500;
      color: $black;
      line-height: 7.5rem;
      margin-bottom: 3rem;
    }
  }

  @media screen and (max-width: 790px) {
    footer {
      p {
        font-size: 3.5rem;
        line-height: 3.2rem;
      }
    }
  }
}
