@import '../../scss/mixins';
@import '../../scss/variables';

.projection_card {
	padding: 12px;
	border-radius: 4px;
	border: 1px solid #c8c8c9;
	@include background-color($appWhite, $appBlack);
	h6 {
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 19.2px;
		letter-spacing: 0.08px;
		margin-bottom: 8px;
		@include color($appBlack, $appWhite);
	}
	p {
		font-size: 14px;
		font-weight: 300;
		line-height: 16.8px;
		letter-spacing: 0.07px;
		margin-bottom: 12px;
		@include color($appBlack, $appWhite);
	}
	.card_bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.alert {
			display: flex;
			align-items: center;
			column-gap: 8px;

			.icon_wrapper {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgba(230, 57, 70, 0.2);
			}

			span {
				color: $appGrey;
				font-size: 12px;
				font-weight: 400;
				line-height: 14.4px;
				letter-spacing: 0.06px;
			}
		}
		.trash_icon {
			@include dark {
				path {
					stroke: $appWhite;
				}
			}
		}
	}
}
