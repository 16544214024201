@import '../../../scss/variables';
@import '../../../scss/mixins/index';
.affiliate_page {
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;
		gap: 24px;

		@media (max-width: 768px) {
			flex-direction: column;
		}

		.title {
			font-size: 24px;
			font-style: normal;
			font-weight: 500;
			line-height: 28.8px;
			letter-spacing: 0.12px;
		}

		.btns {
			display: flex;
			align-items: center;
			gap: 16px;
		}
	}

	.aff_card_wrapper {
		display: flex;
		gap: 32px;
		margin-bottom: 24px;
		@media (max-width: 768px) {
			flex-direction: column;
		}

		.cards_wrapper {
			flex: 2 1 0%;
			display: flex;
			flex-direction: column;

			> span {
				font-size: 16px;
				margin-bottom: 12px;
			}

			.cards {
				display: flex;
				justify-content: space-between;
				gap: 16px;
				flex-wrap: wrap;
				margin-bottom: 35px;

				.info_rate_card {
					flex: 1 1 30%;
				}
			}
		}
		.withdrawal_details_wrapper {
			padding: 20px;
			border-radius: 4px;
			flex: 1 1 0%;
			@include background-color($appWhite, $appBlack);
			// min-height: 100%;

			.no_account {
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 24px;

				span {
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 19.2px;
					letter-spacing: 0.08px;
					text-align: center;
				}

				button {
					width: 100%;
					max-width: 135px;
				}
			}

			.payout_account_details {
				width: 100%;
				// height: 100%;
				.heading {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 16px;

					h4 {
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 19.2px;
						letter-spacing: 0.08px;
					}

					span {
						color: $appGain;
						font-size: 14px;
						font-weight: 400;
						line-height: 16.8px;
						letter-spacing: 0.07px;
						text-decoration-line: underline;
					}
				}

				table {
					border-collapse: collapse;
					tr td {
						border-bottom: 1px solid $appGrey2;
						padding: 12px;

						&:nth-child(2) {
							color: $appGrey;
						}
					}
				}
			}
		}
	}

	.journal_table_wrapper {
		padding: 20px;
		margin-top: 24px;
		border-radius: 8px;
		background: #fff;
		@include background-color($appWhite, $appBlack);

		@media (max-width: 768px) {
			padding: 0;
		}
		.filters {
			padding: 9px 14px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 10px;
			@include background-color($fill, $fill-dm);

			.ant-picker {
				background-color: transparent;
			}

			.filter {
				border-radius: 8px;
				background-color: #1d3557;
				padding: 8px 12px;
				display: flex;
				align-items: center;
				gap: 8px;

				span {
					color: #fff;
					font-size: 14px;
					font-weight: 400;
					line-height: 16.8px;
					letter-spacing: 0.07px;
				}
			}

			.ant-select-selector {
				background-color: transparent;
			}
		}
	}
}
