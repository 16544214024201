@import "../../../scss/variables";
@import "../../../scss/mixins/index";
.trackers_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      @include color($appBlack, $appWhite);
      font-family: Rubik;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 28.8px;
      letter-spacing: 0.12px;
    }
  }

  .tracker_list_wrapper {
    height: 100%;
    @include background-color($appWhite, $appBlack);
    padding: 40px;
    border-radius: 8px;

    @media (max-width: 768px) {
      @include background-color(transparent, transparent);
      padding-right: 0;
      padding-left: 0;
    }
    .empty_component {
		margin: 0 auto;
      max-width: 533px;
      text-align: center;
      color: #92929d;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.018px;
    }
    .tracker_list {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .tracker_card {
        position: relative;
        border-radius: 12px;
        border: 1px solid #c8c8c9;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 600px) {
          flex-direction: column;
          align-items: start;
          // justify-content: center;
          gap: 16px;
        }

        .title {
          flex: 1 1 0%;
          display: flex;
          align-items: center;
          gap: 8px;
          align-items: center;

          .tracker_status {
            border-radius: 50%;
            width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;
            background-color: #c8c8c9;
            &.ongoing {
              background-color: #217eec;
            }
            &.upcoming {
              background-color: #57f18c;
            }
          }
          span {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            @include color($appBlack, $appWhite);
          }
        }
        .date_wrapper {
          justify-content: center;
          flex: 1 1 0%;
          display: flex;
          align-items: center;
          gap: 12px;
          .date {
            @include color($fill-dm, $fill);
            font-size: 14px;
            font-weight: 300;
            line-height: 16.8px;
            letter-spacing: 0.07px;
          }
          .count {
            border-radius: 9px;
            background: $appGain;
            padding: 2px 4px;
            color: #f7f7fc;
            font-size: 12px;
            font-weight: 300;
            line-height: 14.4px;
            letter-spacing: 0.06px;
          }
        }
        .action {
          flex: 1 1 0%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 20px;

          @media (max-width: 768px) {
            display: none;
          }

          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            cursor: pointer;
          }
          .view {
            color: $appGain;
            text-decoration-line: underline;
          }
          .delete {
            color: $appFail;
          }
        }
        .dropdown {
          display: none;
          position: absolute;
          right: 10px;

          @media (max-width: 767px) {
            display: block;
          }
        }
      }
    }
  }
}
