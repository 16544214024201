@import "../../scss/index.scss";

.landing-theme_switcher {
  margin-left: 100px;
  margin-top: 8px;
  @media screen and (max-width: 550px) {
    margin-left: 50px;
  }
}
.landing-page_container {
  box-sizing: border-box;
  min-height: 100vh;
  max-width: 100%;
  background-color: $landing-page-color;
  padding-top: 2rem;
  color: $main-text-color;
  font-size: $normal-text-size;

  .hero-section {
    width: 65%;
    margin: 0 auto;
    margin-top: 5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .fixed-curvy-arrow {
      position: absolute;
      right: 3%;
      top: 8%;
    }

    .hero-head_text {
      font-size: 3.5rem;
      font-weight: 400;
    }
    .hero-sub_text {
      font-size: 1.2rem;
      letter-spacing: 0.02px;
      margin-top: 1.8rem;
      margin-bottom: 1.8rem;
    }
    .hero-btn_container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
    }
    .hero-img {
      margin-top: 1.8rem;
    }
  }
  .rest_of_body {
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 3rem;

    .quote-container {
      display: flex;
      width: 70vw;
      margin: 12rem auto;
      .flex-1 {
        flex: 1;
        display: flex;
        p {
          font-size: 1.8rem;
          font-weight: 500;
          padding-right: 1.2rem;
          line-height: 45px;
          letter-spacing: -0.16px;
        }
        .vertical-separator {
          width: 1px;
          height: 7rem;
          background: linear-gradient(
            180deg,
            rgba(146, 146, 157, 0) 2.86%,
            #92929d 53.28%,
            rgba(146, 146, 157, 0) 100%
          );
        }
      }
      .flex-2 {
        flex: 1;
        padding-left: 4rem;
        .quote {
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.018px;
        }
        .person-detail {
          display: flex;
          align-items: center;
          margin-top: 1rem;
          gap: 12px;
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          .name {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .hero-section {
      width: 80%;
      .hero-head_text {
        font-size: 2.4rem;
        font-weight: 600;
      }
      .hero-sub_text {
        font-size: 1rem;
      }
    }
    .rest_of_body {
      .quote-container {
        margin: 6rem auto;
        display: block;
        .flex-1 {
          p {
            line-height: 2.2rem;
          }
        }
        .flex-2 {
          padding: 0;
          margin-top: 2rem;
          color: rgb(98, 98, 98);
          .quote {
            font-size: 16px;
          }
        }
      }
    }
  }
}
