@import '../../scss/variables';
@import '../../scss/mixins';
.custom_input {
	font-family: 'Rubik', sans-serif;
	position: relative;
	display: flex;
	flex-direction: column;

	> label {
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 8px;
		@include color($appBlack, $appWhite);
	}

	input,
	select,
	textarea,
	.phone_input {
		width: 100%;
		border-radius: 4px;
		border: 1px solid #92929d;
		padding: 12px;
		height: 43px;
		font-size: 16px;
		font-weight: 500;
		line-height: 16.2px;
		color: #888888;
		@include background-color($fill, $fill-dm);

		&:disabled {
			cursor: not-allowed;
			// background: gray;
		}
		&::placeholder {
			color: $appGrey2;
			font-size: 14px;
			font-weight: 300;
			line-height: normal;
		}
		&.error {
			transition: border-color 0.3s ease-out;
			border-color: #d50000;
			color: #d50000;
		}
	}

	.phone_input {
		input {
			border: none;
			padding: 0;
			height: fit-content;
		}
	}
	.password_input {
		position: relative;
		display: flex;
		align-items: center;

		.eye_icon {
			position: absolute;
			right: 5px;
			cursor: pointer;
		}
	}

	.error_message {
		color: #d50000;
		position: absolute;
		bottom: -20px;
		pointer-events: none;
	}
	textarea {
		padding-top: 12px;
		padding-bottom: 12px;
		resize: none;
		min-height: 143px;

		&:focus {
			outline: 0;
		}
	}

	.ant-picker.custom_date_picker,
	.ant-picker.custom_time_picker {
		padding: 12px;
		height: 43px;
		border: 1px solid #92929d;
		@include background-color($fill, $fill-dm);
		border-radius: 4px;

		&.ant-picker-focused {
			box-shadow: none;
		}
		input {
			font-family: 'Rubik', sans-serif;
			color: #888888;
			font-size: 16px;
			font-weight: 400;
			line-height: 16.2px;
			border: none;

			&::placeholder {
				color: $appGrey2;
				font-size: 14px;
				font-weight: 300;
				line-height: normal;
			}
		}

		.ant-picker-clear {
			@include background-color($fill, $fill-dm);
		}

		&.error {
			transition: border-color 0.3s ease-out;
			border-color: #d50000 !important;
		}
	}

	.ant-select.custom_select {
		height: 43px;
		.ant-select-selector {
			border-radius: 4px;
			border: 1px solid #92929d;
			@include background-color($fill, $fill-dm);
			align-items: center;

			.ant-select-selection-search {
				display: flex;
				align-items: center;

				input {
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 16.2px;
					color: #888888;
				}
			}

			.ant-select-selection-item {
				font-weight: 500;
				font-size: 16px;
				line-height: 16.2px;
				color: #888888;
			}
		}
		.ant-select-selection-placeholder {
			color: $appGrey2;
			font-size: 14.5px;
			font-weight: 400;
			line-height: normal;
		}

		&.error {
			.ant-select-selector {
				transition: border-color 0.3s ease-out;
				border-color: #d50000 !important;
			}
		}
	}
}
