@import '../../scss/variables';
@import '../../scss/mixins/index';

.payment_plan_modal {
	padding: 0;

	.ant-modal-content {
		padding: 0;
		@include background-color(#fff !important, $fill-dm !important);
	}
	.payment_plan_wrapper {
		position: relative;

		display: flex;
		align-items: center;
		@include color($appBlack, $fill);

		.plan_features {
			flex: 1.3;
			padding: 65px 45px;
			@include background-color($fill, $appBlack);

			@media (max-width: 767px) {
				padding: 35px 20px;
			}

			h3 {
				font-size: 32px;
				font-weight: 500;
				line-height: 45px;
				letter-spacing: -0.16px;
				margin-bottom: 12px;
			}
			h6 {
				font-size: 20px;
				font-weight: 400;
				line-height: 28px;
				letter-spacing: -0.02px;
				margin-bottom: 24px;
			}
			ul {
				list-style: none;
				li {
					padding: 16px 0;
					border-bottom: 1px solid #c8c8c9;
					display: flex;
					align-items: center;
					gap: 16px;
					span {
						font-size: 20px;
						font-weight: 400;
						line-height: 28px;
						letter-spacing: -0.02px;
					}
				}
			}
		}
		.billing_wrapper {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 16px;

			@media (max-width: 768px) {
				display: none;
			}
		}
		.close_icon {
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;

			@media (max-width: 768px) {
				top: 40px;
				right: 10px;
			}
		}
		.m_billing {
			display: none;
			@media (max-width: 768px) {
				display: block;
			}
		}
		.billing {
			display: flex;
			flex-direction: column;
			align-items: center;

			h6 {
				font-size: 20px;
				font-weight: 400;
				line-height: 28px;
				letter-spacing: -0.02px;
				margin-bottom: 20px;
			}

			.tabs_wrapper {
				max-width: fit-content;
				margin-bottom: 12px;
				overflow: hidden;
				border-radius: 12px;
				border: 1px solid $appBlack;

				@include dark {
					border-color: $fill;
				}
				display: flex;
				span {
					width: 169px;
					text-align: center;
					padding: 10px 4px;
					cursor: pointer;

					&.active {
						@include background-color($appBlack, $fill);
						@include color(#fff, $appBlack);
					}
				}
			}

			.plan_payment {
				display: flex;
				align-items: flex-end;
				gap: 8px;
				margin-bottom: 12px;

				span {
					font-size: 18px;
					font-weight: 400;
					line-height: 26px;
					letter-spacing: -0.018px;
					&.currency,
					&.price {
						@include color($appBlack, $fill);
					}
					&.price {
						line-height: 64px;
						font-size: 70px;
					}
					&.billed {
						color: $appGrey;
					}
				}
			}

			.info {
				display: block;
				@include color($appBlack, $fill);
				font-size: 16px;
				font-weight: 300;
				line-height: 22px;
				margin-bottom: 46px;
			}

			button {
				cursor: pointer;
				padding: 16px;
				width: 100%;
				border-radius: 100px;
				background: #04f6b5;
				color: $appBlack;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 22px;
				border: none;
			}
		}
	}
}
