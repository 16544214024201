#notificationHeader {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }
  
  #notificationBody {
    margin-top: 10px;
    text-align: center;
  }
  
  #imageContainer {
    display: flex;
    align-items: center;
    height: 100px;
    object-fit: contain;
  }