@import '../../../scss/variables';
@import '../../../scss/mixins';
.projection_page {
	.top {
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 16px;
		.page_heading {
			@include color($appBlack, $appWhite);
			font-size: 24px;
			font-weight: 500;
			line-height: 28.8px;
		}
		button {
			// width: 189px;
		}
	}
	.projections_wrapper {
		min-height: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		border-radius: 8px;
		padding: 32px 24px;
		@include background-color($appWhite, $appBlack);

		.projection_card {
			flex: 1 1 31%;
		}

		&.coming_soon {
			display: flex;
			align-items: center;
			justify-content: center;
			height: calc(100vh - 200px);
		}
	}
}
.add_projection_modal {
	.top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;

		span {
			font-size: 20px;
			font-weight: 500;
			letter-spacing: 0.1px;
			@include color($appBlack, $appWhite);
		}

		svg path {
			@include dark {
				stroke: $appWhite;
			}
			@include light {
				stroke: $appBlack;
			}
		}
	}

	.mb-16 {
		margin-bottom: 16px;
	}
	.date_time {
		display: flex;
		align-items: center;
		gap: 22px;

		> .custom_input {
			flex: 1;
		}
	}
	> button {
		width: 100%;
		margin-top: 28px;
	}
}
