@import '../../scss/variables';
@import '../../scss/mixins/index';
.trade_card {
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 12px 4px;
	cursor: pointer;
	border-bottom: 1px solid #eeeeee;
	.order_type {
		min-width: 27px;
		min-height: 27px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		&.buy {
			background-color: $appGain;
		}
		&.sell {
			background-color: $appFail;
		}
	}

	.details,
	.aside {
		display: flex;
		flex-direction: column;
		row-gap: 8px;

		span:nth-child(1) {
			@include color($fill-dm, $fill);
			font-weight: 400;
			font-size: 14px;
		}
		span:nth-child(2) {
			color: #92929d;
			font-weight: 300;
			font-size: 12px;
		}
	}
	.aside {
		align-items: flex-end;
		margin-left: auto;
	}
}
