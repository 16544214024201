@import '../../scss/variables';
@import '../../scss/mixins/index';
.affiliate_link_modal {
	display: flex;
	flex-direction: column;

	> span {
		font-size: 16px;
		font-weight: 400;
		line-height: 19.2px;
		letter-spacing: 0.08px;
		// @include color($fill-dm, $fill);
		margin-bottom: 16px;
	}

	.link_wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px;
		border-radius: 4px;
		border: 1px solid $appBlack;
		background: transparent;

		@include dark {
			border-color: $appWhite;
		}

		.copy_btn {
			display: flex;
			align-items: center;
			gap: 8px;
			cursor: pointer;

			span {
				font-size: 16px;
				font-weight: 400;
				line-height: 19.2px;
				letter-spacing: 0.08px;
				color: $appGain;
			}
		}
	}
}
