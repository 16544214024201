@import '../../scss/index.scss';

.pricing-container {
	background-color: $landing-page-color;
	min-height: 100vh;
	padding-top: 2rem;
	.pricing_header_container {
		text-align: center;
		h1 {
			font-size: 4.3rem;
			font-weight: 400;
			line-height: 90px;
			margin-top: 3%;
		}
		h2 {
			font-size: 1.5rem;
			font-weight: 400;
			margin-top: 15px;
		}
		.plan_switch_container {
			position: relative;
			//   background-color: orange;
			margin-top: 1.5rem;
			padding: 0.5rem;
			font-size: 24px;
			font-style: normal;
			font-weight: 400;
			line-height: 30px;
			letter-spacing: -0.048px;
			color: $appGrey;
			.monthly_text {
				margin-right: 1%;
			}
			.anual_text {
				margin-left: 1%;
			}
			#active_plan {
				color: $main-text-color;
			}
			.fixed_discount_arrow {
				right: 34.7%;
				position: absolute;
				margin-top: -1%;
			}
		}
		@media screen and (max-width: 1050px) {
			.fixed_discount_arrow {
				right: 22% !important;
			}
		}
		@media screen and (max-width: 550px) {
			h1 {
				font-size: 3rem;
				margin-top: 2rem;
			}
			h2 {
				font-size: 1rem;
				margin-top: 5px;
			}
			.plan_switch_container {
				font-size: 1rem;
			}
			.fixed_discount_arrow {
				right: 5% !important;
			}
		}
	}
	.plans_container {
		margin-top: 7%;
		padding: 2%;
		display: flex;
		gap: 3%;
		justify-content: center;
		flex-wrap: wrap;
		position: relative;
		.plan_card {
			background: $gradient-container-background;
			width: 500px;
			height: 650px;
			padding: 32px;
			gap: 10px;
			border-radius: 12px;
			border: 1px solid rgb(70, 70, 70);
			margin-bottom: 1.5rem;
			.plan_title {
				font-size: 32px;
				font-style: normal;
				font-weight: 300;
				line-height: 45px; /* 140.625% */
				letter-spacing: -0.16px;
				margin-bottom: 3%;
			}
			.plan_amount {
				color: $appGrey;
				/* Subheading 1 */
				font-family: Rubik;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 26px;
				letter-spacing: -0.018px;
				.currency {
					color: $main-text-color;
				}
				.figure {
					color: $main-text-color;
					font-size: 70px;
					font-style: normal;
					font-weight: 400;
					line-height: 90px;
				}
			}

			ul {
				list-style: none;
				margin-top: 10%;
				li {
					border-bottom: 1px solid $appGrey;
					font-family: Rubik;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: 28px; /* 140% */
					letter-spacing: -0.02px;
					padding: 3% 0;
				}
			}
			.btn_container {
				bottom: 8%;
			}
		}
		@media screen and (max-width: 1050px) {
			.plan_card {
				width: 450px;
			}
		}
		@media screen and (max-width: 550px) {
			margin-top: 4rem;
			.plan_card {
				margin-bottom: 1.5rem;
			}
		}
	}

	.why_go_pro_container {
		padding: 40px 67px 40px 66px;
		display: inline-flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		background: #1a1a1a;
		width: 100vw;
		.container_title {
			font-size: 32px;
			font-style: normal;
			font-weight: 500;
			line-height: 45px; /* 140.625% */
			letter-spacing: -0.16px;
			margin-bottom: 2rem;
		}

		.inner_container {
			display: inline-flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			gap: 32px;
		}
	}
}

.price_reason_card {
	width: 409px;
	height: 196px;
	max-width: 90vw;

	border-radius: 12px;
	border: 1px solid rgba(146, 146, 157, 0.5);
	background: linear-gradient(257deg, rgba(47, 47, 47, 0.8) -12.88%, rgba(47, 47, 47, 0) 109.21%);
	display: flex;
	padding: 24px 17px;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
	color: white;
	.tick_icon {
		font-size: 40px;
	}
	.title {
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px; /* 140% */
		letter-spacing: -0.02px;
		color: white;
	}
	.description {
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 22px; /* 137.5% */
	}
}
