@import '../../../scss/variables';
@import '../../../scss/mixins/index';
.admin_page {
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;
		gap: 24px;

		@media (max-width: 768px) {
			flex-direction: column;
		}

		.title {
			font-size: 24px;
			font-style: normal;
			font-weight: 500;
			line-height: 28.8px;
			letter-spacing: 0.12px;
		}

		.btns {
			display: flex;
			align-items: center;
			gap: 16px;
		}
	}

	.cards {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 24px;

		.info_rate_card {
			flex: 1;
		}
	}

	.journal_table_wrapper {
		padding: 20px;
		margin-top: 24px;
		border-radius: 8px;
		background: #fff;
		@include background-color($appWhite, $appBlack);

		@media (max-width: 768px) {
			padding: 0;
		}
		.filters {
			padding: 9px 14px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 10px;
			@include background-color($fill, $fill-dm);

			.ant-picker {
				background-color: transparent;
			}

			.filter {
				border-radius: 8px;
				background-color: #1d3557;
				padding: 8px 12px;
				display: flex;
				align-items: center;
				gap: 8px;

				span {
					color: #fff;
					font-size: 14px;
					font-weight: 400;
					line-height: 16.8px;
					letter-spacing: 0.07px;
				}
			}

			.ant-select-selector {
				background-color: transparent;
			}
		}

		.user_detail {
			display: flex;
			flex-direction: column;
			span:nth-child(1) {
				margin-bottom: 10px;
				font-size: 14px;
				@include color(#01161e, #fff);
			}
			span:nth-child(2) {
				font-size: 14px;
				color: #92929d;
			}
		}
	}
}
