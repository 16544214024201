@import "../../scss/index.scss";

.contact_page {
  background-color: $landing-page-color;
  min-height: 100vh;
  padding-top: 2rem;

  .heading_box {
    width: 680px;
    max-width: 90vw;
    margin: 0 auto;
    margin-top: 5rem;
    h1 {
      font-size: 70px;
      font-style: normal;
      font-weight: 400;
      line-height: 90px;
      text-align: center;
    }
    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.048px;
      text-align: center;
      margin-top: 2rem;
    }
    @media screen and (max-width: 550px) {
      margin-top: 4rem;
      h1{
        font-size: 40px;
        line-height: 50px;
      }
      h2{
        font-size: 16px;
      }
    }
  }
  .form_container {
    margin: 0 auto;
    margin-top: 4rem;
    width: 723px;
    height: 673px;
    max-width: 90vw;
    padding: 4%;
    gap: 10px;

    border-radius: 12px;
    border: 1px solid rgba(146, 146, 157, 0.5);
    background: $gradient-container-background;
    .form_item {
      width: 100%;
      margin-bottom: 3rem;
    }
    .name_container {
      display: inline-flex;
      gap: 20px;
      width: 100%;
    }
    .message {
      width: 100%;
      margin-bottom: 3rem;
    }
    @media screen and (max-width: 550px) {
      padding: 6%;
      padding-top: 15%;
    }
  }
}
