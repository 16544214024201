$landing-page-color: var(--landing-page-color);
$color-primary: var(--color-primary);
$background-color-primary: var(--background-color-primary);
$background-color-secondary: var(--background-color-secondary);
$landing-nav-background: var(--landing-nav-background);
$main-text-color: var(--main-text-color);
$faded-text-color: var(--faded-text-color);

$landing-btn-fill: var(--landing-btn-fill);
$landing-btn-border: var(--landing-btn-border);
$landing-btn-text-color: var(--landing-btn-text-color);
$reason-container-border: var(--reason-container-border);
$reason-container-fill: var(--reason-container-fill);
$input-border-color: var(--input-border-color);
$input-background-color: var(--input-background-color);
$gradient-container-background: var(--gradient-container-background);

//General color declarations

$light-green: #04f6b5;
$mid-green: #02a77b;
$main-green: #04f6b5;
$black: #000;

//texts
$heading-1: 2.5rem;
$heading-2: 1.5rem;
$heading-3: 1.2rem;
$normal-text-size: 1.1rem;

//texts mobile
$heading-1-mobile: 2rem;
$heading-2-mobile: 1.2rem;
$heading-3-mobile: 1rem;
$normal-text-size: 16px;


$primary: #e63946;
$appBlack: #01161e;
$appFail: #f85454;
$appGain: #217eec;
$appWhite: #fff;
$appGrey: #92929d;
$appGrey2: #c8c8c9;
$fill: #f7f7fc;
$fill-dm: #252836;
