.trade_status {
	display: flex;
	align-items: center;
	gap: 4px;
	border-radius: 10px;
	padding: 2px 6px;
	width: fit-content;

	> div {
		width: 4px;
		height: 4px;
		border-radius: 100%;
	}

	span {
		font-size: 13px;
		font-weight: 400;
		letter-spacing: 0.065px;
		text-transform: capitalize;
	}

	&.open {
		background: rgba(87, 241, 140, 0.2);
		border: 1px solid #57f18c;

		div {
			background-color: #57f18c;
		}

		span {
			color: #57f18c;
		}
	}

	&.Profit {
		background: rgba(33, 126, 236, 0.2);
		border: 1px solid #217eec;

		div {
			background-color: #217eec;
		}

		span {
			color: #217eec;
		}
	}
	&.Loss {
		background: rgba(248, 84, 84, 0.2);
		border: 1px solid #f85454;

		div {
			background-color: #f85454;
		}

		span {
			color: #f85454;
		}
	}
	&.Breakeven {
		background: rgba(146, 146, 157, 0.2);
		border: 1px solid #92929d;

		div {
			background-color: #92929d;
		}

		span {
			color: #92929d;
		}
	}
}
