@import '../../scss/variables';
@import '../../scss/mixins/index';
.payout_drawer {
	@include dark {
		svg {
			fill: $appWhite;
		}
	}
	.tabs_wrapper {
		display: flex;
		border-bottom: 2px solid #f7f7fc;
		padding-bottom: 16px;
		.tab {
			cursor: pointer;
			position: relative;
			text-align: center;
			flex: 1;
			font-size: 14px;
			color: #92929d;

			&.active {
				@include color($appBlack, $appWhite);

				&::after {
					content: '';
					height: 4px;
					border-radius: 2px;
					position: absolute;
					width: 100%;
					bottom: -16px;
					left: 0;
					background-color: #1d3557;
				}
			}
		}
	}

	.trx_list {
		margin-top: 24px;
		display: flex;
		flex-direction: column;
		gap: 20px;

		.trx {
			display: flex;
			flex-direction: column;
			gap: 16px;
			border-radius: 8px;
			padding: 12px;
			@include background-color(#f7f7fc, $appBlack);
			.date {
				font-size: 12px;
				@include color($appBlack, #fff);
				span {
					color: #92929d;
				}
			}
			.info {
				@include color($appBlack, #fff);
			}
			.actions {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 12px;

				span {
					cursor: pointer;
					color: $appGain;
					text-decoration: underline;
				}
			}
		}
	}
}
