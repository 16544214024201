@import '../../scss/mixins';
@import '../../scss/variables';
.info_rate_card {
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 8px;
	@include background-color($appWhite, $appBlack);

	> span {
		font-size: 14px;
		font-weight: 400;
		line-height: 16.8px;
		letter-spacing: 0.07px;
		@include color($fill-dm, $fill);
	}
	.bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.figure {
			display: flex;
			align-items: center;
			column-gap: 2px;

			span {
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 19.2px;
				letter-spacing: 0.08px;
				@include color($appBlack, $appWhite);
			}
		}

		> span {
			font-family: 'Mulish', sans-serif;
			font-size: 12px;
			font-style: normal;
			font-weight: 700;
			line-height: 14.4px;
			letter-spacing: 0.06px;

			&.growth {
				color: #217eec;
			}
			&.loss {
				color: #f85454;
			}
		}
	}
}
