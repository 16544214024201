.welcome_video_wrapper {
  iframe {
    width: 100%;
    height: 600px;
    @media screen and (max-width: "550px") {
      height: 400px;
    }
  }
  h2{
      margin-top: 1.5rem;
      @media screen and (max-width: 768px) {
          margin-top: 3rem;
      }
  }
  .text_details {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  .close_icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    @media (max-width: 768px) {
      top: 40px;
      right: 10px;
    }
  }
}
