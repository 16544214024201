@import '../../scss/index.scss';

.btn {
	cursor: pointer;
	font-family: 'Rubik', sans-serif;
	min-width: 3rem;
	// height: 2.4rem;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	letter-spacing: -0.018px;
	border-radius: 100px;
	border: none;
	padding: 14px 16px;
	align-items: center;
	justify-content: center;

	.btn-text {
		margin-left: 5px;
	}

	&:disabled {
		cursor: not-allowed;
	}
}
#landing_btn {
	border: $landing-btn-border;
	color: $landing-btn-text-color;
	background: $landing-btn-fill;
}
