@import '../../../scss/mixins';
@import '../../../scss/variables';
.overview_page {
	.date {
		font-size: 16px;
		font-weight: 400;
		line-height: 19.2px;
		letter-spacing: 0.08px;
		@include color($appBlack, $appWhite);
	}

	.top_cards {
		margin-top: 32px;
		display: flex;
		gap: 20px;
		margin-bottom: 24px;
		flex-wrap: wrap;

		.info_rate_card {
			flex: 1 1 0%;
			@media (max-width: 1024px) {
				flex: 1 1 200px;
			}
			@media (max-width: 768px) {
				flex: 1 1 45%;
			}
		}
	}

	.trade_details_wrapper {
		display: flex;
		align-items: stretch;
		justify-content: stretch;
		gap: 32px;

		@media (max-width: 1024px) {
			flex-direction: column;
		}

		.chart_wrapper {
			flex: 2.5 1 0%;
			padding: 20px;
			border-radius: 8px;
			@include background-color($appWhite, $appBlack);

			.chart_top {
				margin-bottom: auto;
				display: flex;
				align-items: center;
				gap: 20px;

				.title {
					margin-right: auto;
					@include color($appBlack, $appWhite);
					font-size: 16px;
					font-weight: 500;
					line-height: 19.2px;
					letter-spacing: 0.08px;
				}
			}
		}

		.open_trades {
			flex: 1 1 10%;
			padding: 20px;
			border-radius: 12px;
			border: 1px solid #c8c8c9;
			@include background-color($appWhite, $appBlack);
			max-width: 392px;
			height: 100%;
			max-height: 392px;
			overflow-y: auto;

			@media (max-width: 1024px) {
				max-height: 100%;
				max-width: 100%;
			}

			@include dark {
				border: 1px solid #3c4156;
			}

			> span {
				font-size: 16px;
				margin-bottom: 12px;
				display: block;
			}
			.trade_list {
				display: flex;
				flex-direction: column;
				column-gap: 16px;

				.no_item {
					color: #92929d;
					font-size: 14px;
					margin-top: 16px;
					text-align: center;
				}
			}
		}
	}
	.recomendation_projection {
		margin-top: 22px;
		display: flex;
		align-items: stretch;
		align-items: normal;
		// align-items: flex-start;
		gap: 17px;
		.projection_wrapper {
			flex: 1 1 0%;
			max-width: 378px;
			padding: 20px 12px;
			border-radius: 8px;
			@include background-color($appWhite, $appBlack);
			max-height: 405px;
			overflow-y: auto;

			@media (max-width: 768px) {
				max-height: 100%;
				max-width: 100%;
			}

			.header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 12px;

				h6 {
					font-size: 16px;
					font-weight: 500;
					line-height: 19.2px;
					letter-spacing: 0.08px;
					@include color($appBlack, $appWhite);
				}
			}
			.projections {
				display: flex;
				flex-direction: column;
				gap: 8px;
			}

			.view_all {
				display: flex;
				align-items: center;
				gap: 4px;
				cursor: pointer;
				span {
					color: #217eec;
					font-size: 16px;
					font-weight: 400;
					line-height: 19.2px;
					letter-spacing: 0.08px;
					text-decoration-line: underline;
				}
			}
		}

		.recomemdedation_wrapper {
			max-height: 405px;
			height: 100%;
			flex: 1 1 0%;
			max-width: 378px;
			padding: 20px 12px;
			border-radius: 8px;
			@include background-color($appWhite, $appBlack);

			@media (max-width: 768px) {
				max-height: 100%;
				max-width: 100%;
			}
			h6 {
				font-size: 16px;
				font-weight: 500;
				line-height: 19.2px;
				letter-spacing: 0.08px;
				@include color($appBlack, $appWhite);
			}
			.recomemdedation_list {
				margin-top: 16px;
				display: flex;
				flex-direction: column;
				gap: 8px;
			}
		}

		@media (max-width: 768px) {
			flex-direction: column;

			.projection_wrapper {
				width: 100%;
			}
			.recomemdedation_wrapper {
				width: 100%;
			}
		}
	}
}
