@import url("https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@600&family=Mulish:wght@300;400;500;600;700&family=Rubik:wght@300;400;500;600;700&display=swap");
@import "./scss/variables";
@import "./scss/mixins";
@import './scss/index.scss';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // transition: 0.2s ease-in-out;
}
// iframe#webpack-dev-server-client-overlay{display:none!important}

html {
  // cursor: none;
  scroll-behavior: smooth;
}

// font-family: 'Gemunu Libre', sans-serif;
body {
  position: relative;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-variant: normal;
  font-size: 14px;
  margin: 0 auto;
  @include color($appBlack, $appWhite);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  color: inherit;
}

input {
  outline: 0;
}
ul,
li {
  margin: 0;
  padding: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-table-wrapper.journal_table {
  .ant-table {
    background-color: transparent;
  }
  .ant-table-thead > tr > th {
    @include color($appBlack, $appWhite);
    background-color: transparent;
    // border-bottom: 1px solid $background-color-primary;
    background: transparent;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    letter-spacing: 0.08px;

    @include light {
      border-bottom: 1px solid $appBlack;
    }
    @include dark {
      border-bottom: 1px solid $appWhite;
    }

    &::before {
      display: none;
    }
  }

  .ant-table-tbody tr {
    &:hover {
      td {
        background-color: inherit;
      }
    }
    td {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      letter-spacing: 0.07px;
      border-bottom: 1px solid #92929d;
      @include color($appBlack, $appWhite);

      .status {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .action {
        display: flex;
        column-gap: 20px;
        align-items: center;

        .close {
          cursor: pointer;
          color: #217eec;
        }
        .view {
          cursor: pointer;
          display: flex;
          align-items: center;
          column-gap: 4px;

          svg path {
            @include dark {
              stroke: $appWhite;
            }
          }
        }
      }
    }
  }
}

.app {
  *::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-selector:hover,
.ant-select-open .ant-select-selector {
  // border-color: #92929d !important;
  // box-shadow: none !important;
}

.button {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  letter-spacing: 0.08px;
  display: flex;
  align-items: center;
  gap: 8px;

  &.primary,
  &.secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    cursor: pointer;
    border: 1px solid #e63946;
    padding: 12px 16px;
    color: white;
    border-radius: 100px;
    background: #e63946;

    svg path {
      stroke: #fff !important;
    }

    &:hover {
      transition: all 0.3s ease;
      background-color: transparent;
      color: #e63946;

      svg path {
        stroke: #e63946 !important;
      }
    }

    &:disabled {
      border: none;
      cursor: not-allowed;
      background-color: #c8c8c9;
      color: white;
      &:hover {
        color: white;
        svg path {
          stroke: white !important;
        }
      }
    }

    @media (max-width: 639px) {
      padding: 6px 20px;
      font-size: 12px;
    }
  }

  &.secondary {
    border: 1px solid #e63946;
    background-color: transparent;
    color: #e63946;

    svg path {
      stroke: #e63946 !important;
    }

    &:hover {
      transition: all 0.3s ease;
      background-color: #e63946;
      color: white;

      .ant-spin-dot-item {
        background-color: white;
      }

      svg path {
        stroke: #fff !important;
      }
    }
  }
}

.ant-modal.custom_modal {
	font-family: 'Rubik', sans-serif;
	padding-bottom: 0;
	.ant-modal-content {
		border-radius: 4px;
		@include background-color($appWhite, $appBlack);
	}
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;

		.title {
			display: flex;
			// align-items: center;
			gap: 16px;

			span {
				cursor: pointer;
			}
		}

		h4 {
			font-size: 24px;
			font-weight: 500;
			line-height: 30px;
			letter-spacing: -0.072px;
			@include color($fill-dm, $fill);
		}

		svg path {
			@include dark {
				stroke: $appWhite;
			}
			@include light {
				stroke: $appBlack;
			}
		}
	}
}

.verification_code_container {
  gap: 12px;
  width: fit-content;
  justify-content: center;
  flex: 1 1 0%;
  width: 100%;
  max-width: 540px;
  height: 74px;
  @media (max-width: 768px) {
    height: 40px;
  }

  .character {
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    // color: $--tangaroa-900;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    max-width: 64px;
    height: 48px;

    @include background-color($fill, $fill-dm);
    @include dark {
      border: 1px solid $appGrey2;
    }
    @include light {
      border: 1px solid $appGrey;
    }

    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
}

.w-full {
  width: 100%;
}

.empty_state {
  display: flex;
  min-height: 200px;
  align-items: center;
  justify-content: center;
  span {
    max-width: 533px;
    text-align: center;
    color: #92929d;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.018px;
  }
}

.share_page_bg {
  padding: 32px 15px;
  @include background-color($fill, $fill-dm);
}

@for $i from 0 through 100 {
  .fs-#{$i} {
    font-size: #{$i}px;
  }
  .w-#{$i} {
    width: #{$i}px;
  }
  .h-#{$i} {
    height: #{$i}px;
  }
  .lh-#{$i} {
    line-height: #{$i}px;
  }
  .br-#{$i} {
    border-radius: #{$i}px !important;
  }

  .space-#{$i} {
    gap: #{$i}px !important;
  }
  .space-x-#{$i} {
    row-gap: #{$i}px !important;
  }
  .space-y-#{$i} {
    column-gap: #{$i}px !important;
  }
  .pad-#{$i} {
    padding: #{$i}px !important;
  }
  .pad-x-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }
  .pad-y-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }
  .pad-l-#{$i} {
    padding-left: #{$i}px !important;
  }
  .pad-r-#{$i} {
    padding-right: #{$i}px !important;
  }
  .pad-t-#{$i} {
    padding-top: #{$i}px !important;
  }
  .pad-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .mar-#{$i} {
    margin: #{$i}px !important;
  }
  .mar-x-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }
  .mar-y-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }
  .mar-l-#{$i} {
    margin-left: #{$i}px !important;
  }
  .mar-r-#{$i} {
    margin-right: #{$i}px !important;
  }
  .mar-t-#{$i} {
    margin-top: #{$i}px !important;
  }
  .mar-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }
}

.d-block {
  display: block;
  img {
    width: 100%;
  }
  .svg-img {
    width: 100%;
    height: 100%;
  }

  .app_logo {
    // margin-left: 32px;
    margin-right: 45px;
    display: flex;
    align-items: center;
    column-gap: 4px;

    span {
      font-family: "Gemunu Libre", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28.8px;
      letter-spacing: 0.12px;
      @include color($appBlack, $appWhite);
    }
  }
}

.svg-img {
  width: 100%;
  height: 100%;
}
.green_blur_shadow {
  top: 0;
  position: fixed;
  z-index: 9;
}

.aside_mobile{
  display: none;
  margin-left: -40%;
  @media screen and (max-width: 767px) {
    display: block;
  }
}
.aside {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    display: none;
  }

  .user {
    display: flex;
    align-items: center;
    gap: 7px;
    .user_image,
    .no_profile {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #d9d9d9;
      object-fit: cover;
    }
    .details {
      display: flex;
      flex-direction: column;
      gap: 4px;

      span:nth-child(1) {
        color: $appBlack;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.07px;

        @include color($appBlack, $appWhite);
      }

      span:nth-child(2) {
        color: #92929d;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        letter-spacing: 0.06px;
      }
    }
  }
}

.landing_dropdown_mobile {
  margin-left: -30%;

  @media (min-width: 769px) {
    display: none;
  }

  .user_image,
  .no_profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #d9d9d9;
    object-fit: cover;
  }
  .drop {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
