@import '../../scss/variables';
.loading {
	position: fixed;
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 3;
	.backdrop {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.1);
	}

	.modal {
		position: absolute;
		width: 80px;
		height: 80px;
		padding: 15px;
		background-color: transparent;
		z-index: 3;
	}

	.loader {
		border-radius: 50%;
		width: 50px;
		height: 50px;
		position: relative;
		border-top: 5px solid rgba(0, 0, 0, 0.03);
		border-right: 5px solid rgba(0, 0, 0, 0.03);
		border-bottom: 5px solid $primary;
		border-left: 5px solid $primary;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-animation: load 1.1s infinite linear;
		animation: load 1.1s infinite linear;
	}
	@keyframes load {
		0% {
			-webkit-transform: rotate(0);
			transform: rotate(0);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
}
