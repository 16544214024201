@import '../../../scss/variables';
@import '../../../scss/mixins';
.add_journal_page {
	padding: 40px 80px;
	border-radius: 8px;
	@include background-color($appWhite, $appBlack);

	@media (max-width: 768px) {
		@include background-color(transparent, transparent);
		padding-right: 0;
		padding-left: 0;
	}

	.confluences-container {
		margin-top: 0.5rem;
		margin-bottom: 1.5rem;
		.heading {
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			margin-bottom: 8px;
			@include color($appBlack, $appWhite);
		}
		.description {
			color: $appGrey;
			margin-bottom: 1rem;
		}
		.add-confluence-text {
			color: #217eec;
			cursor: pointer;
			&:hover {
				color: #175aab;
			}
		}
		.confluence-inner_container {
			.confluence {
				margin-bottom: 0.5rem;
			}
		}
	}

	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;

		h5 {
			font-size: 24px;
			font-weight: 500;
			line-height: 28.8px;
			letter-spacing: 0.12px;
			@include color($appBlack, $appWhite);
		}

		svg path {
			@include dark {
				stroke: $appWhite;
			}
			@include light {
				stroke: $appBlack;
			}
		}
	}

	.image_upload_wrapper {
		margin-top: 32px;
		> span {
			@include color($appBlack, $appWhite);
			font-size: 14px;
			font-weight: 500;
			line-height: 16.8px;
			letter-spacing: 0.07px;
		}
		.image_upload {
			width: 100%;
			max-width: 236px;
			margin-top: 8px;
			border: 1px solid #92929d;
			padding: 12px 16px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 12px;

			@media (max-width: 500px) {
				max-width: 500px;
			}

			.journal_image {
				position: relative;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					max-width: 100%;
				}
				&:hover {
					.delete {
						display: flex;
					}
				}

				.delete {
					display: none;
					top: 0;
					position: absolute;
					background-color: rgba(0, 0, 0, 0.3);
					width: 100%;
					height: 100%;
					align-items: center;
					justify-content: center;

					svg path {
						@include dark {
							stroke: $appWhite;
						}
						@include light {
							stroke: $appBlack;
						}
					}
				}
			}

			label {
				color: #217eec;
				font-size: 14px;
				font-weight: 400;
				line-height: 16.8px;
				letter-spacing: 0.07px;
				text-decoration-line: underline;
			}
		}
	}

	.forms_input {
		width: 100%;
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		// justify-content: space-between;
		// gap: 20px 40px;
		gap: 20px 5%;

		.form_item {
			flex: 1 1 30%;

			@media (max-width: 500px) {
				flex: 1 1 100%;
			}
		}

		.check {
			width: 100%;
		}

		.trade_outcome {
			width: 100%;
			max-width: 30%;
			@media (max-width: 500px) {
				max-width: 100%;
			}
		}
	}

	.w-100 {
		width: 100%;
	}
	textarea {
		// min-height: auto;
		min-height: 68px;
	}

	button {
		width: 100%;
		margin-top: 32px;
	}
}
