@import '../../scss/variables';
@import '../../scss/mixins';
.ant-drawer .ant-drawer-content.trade_details_drawer {
	@include background-color($appWhite, $appBlack);

	.ant-drawer-header {
		border-bottom: none;

		svg path {
			@include dark {
				stroke: $appWhite;
			}
			@include light {
				stroke: $appBlack;
			}
		}
	}

	.top {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.currency {
			margin-bottom: 21px;
			display: flex;
			flex-direction: column;
			span:nth-child(1) {
				@include color($appBlack, $appWhite);
				font-size: 20px;
				font-weight: 500;
				letter-spacing: 0.1px;
				margin-bottom: 8px;
			}
			span:nth-child(2) {
				@include color($appGrey, $appGrey2);
				font-size: 14px;
				font-weight: 300;
				letter-spacing: 0.07px;
			}
		}
	}
	.tarde_image_placeholder {
		position: relative;
		margin-bottom: 24px;
		background-color: #d9d9d9;
		width: 100%;
		height: 190px;
		display: flex;
		align-items: center;
		justify-content: center;

		&::before {
			content: 'No Image';
		}
	}

	.ant-image {
		margin-bottom: 24px;
		.tarde_image {
			object-fit: cover;
			object-position: 50%;
		}
	}
	.trade_details_wrapper {
		border-bottom: 1px solid #92929d;
		padding-bottom: 32px;
		> span {
			@include color($appBlack, $appWhite);
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0.08px;
		}
		.details {
			margin-top: 12px;
			display: flex;
			gap: 12px;
			justify-content: space-between;
			flex-wrap: wrap;
			.single_detail {
				flex: 1 1 46%;
				display: flex;
				flex-direction: column;
				gap: 4px;
				span {
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 120%; /* 16.8px */
					letter-spacing: 0.07px;

					&:nth-child(1) {
						@include color($appGrey, $appGrey2);
					}
					&:nth-child(2) {
						@include color($appBlack, $appWhite);
					}
				}
			}
		}
	}
	.reason,
	.comment {
		margin-top: 32px;
		display: flex;
		flex-direction: column;
		gap: 8px;

		span {
			@include color($appBlack, $appWhite);
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0.08px;
		}
		p {
			@include color($appGrey, $appGrey2);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			letter-spacing: 0.07px;
		}
	}
	.button.secondary {
		margin-top: 24px;
		width: 100%;
	}
}
